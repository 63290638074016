@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --LOGIN_FORM_SHADOW_COLOR:  #BDC6EE;
  --WHITE_COLOR:              #FFF;
  --BACKGROUND_COLOR:         #F7F8FA;
  --BLACK_COLOR:              #000;
  --FEEDBACK_BUTTON_COLOR:    #002141;
  --TEXT_CORRECTION_COLOR:    #C9DBFD;

  /* Primary */
  --PRIMARY_COLOR:  #0045E5;
  --PRIMARY_50:     #9EBBFF;
  --PRIMARY_100:    #89ADFF;
  --PRIMARY_200:    #6090FF;
  --PRIMARY_300:    #3874FF;
  --PRIMARY_400:    #0F57FF;
  --PRIMARY_500:    #0045E5;
  --PRIMARY_600:    #0034AD;
  --PRIMARY_700:    #002375;
  --PRIMARY_800:    #00123D;
  --PRIMARY_900:    #000105;

  /* Secondary */
  --SECONDARY_COLOR:  #002141;
  --SECONDARY_50:     #007EF9;
  --SECONDARY_100:    #0074E4;
  --SECONDARY_200:    #005FBB;
  --SECONDARY_300:    #004A93;
  --SECONDARY_400:    #00366A;
  --SECONDARY_500:    #002141;
  --SECONDARY_600:    #000509;
  --SECONDARY_700:    #000000;
  --SECONDARY_800:    #000000;
  --SECONDARY_900:    #000000;

  --TERTIARY_COLOR:   #FF3900;
  --TERTIARY_50:      #ffebe5;
  --TERTIARY_100:     #FFB8A3;
  --TERTIARY_200:     #FF987A;
  --TERTIARY_300:     #FF7852;
  --TERTIARY_400:     #FF5929;
  --TERTIARY_500:     #FF3900;
  --TERTIARY_600:     #C72C00;
  --TERTIARY_700:     #8F2000;
  --TERTIARY_800:     #571300;
  --TERTIARY_900:     #1F0700;

  /* Orange */
  --ORANGE_50:  #ffeee5;
  --ORANGE_100: #ffccb3;
  --ORANGE_200: #ffaa80;
  --ORANGE_300: #f48458;
  --ORANGE_400: #ff661a;
  --ORANGE_700: #802b00;

  /* Green */
  --GREEN_COLOR:  #0ff80f;
  --GREEN_50:     #f0f9ec;
  --GREEN_200:    #bbe3aa;
  --GREEN_300:    #9bd582;
  --GREEN_400:    #7ac959;
  --GREEN_500:    #09e109;
  --GREEN_600:    #0dab0d;
  --GREEN_700:    #346021;
  --GREEN_900:    #011801;

  /* Red */
  --RED_COLOR:  #e80d15;
  --RED_50:     #ffe6e7;
  --RED_100:    #f8cdce;
  --RED_200:    #e89b9c;
  --RED_300:    #ea595f;
  --RED_400:    #d33137;
  --RED_500:    #e3121a;
  --RED_600:    #b7050b;
  --RED_700:    #7a080c;

  /* Yellow */
  --YELLOW_COLOR: #f6c80e;
  --YELLOW_50:    #fef9e6;
  --YELLOW_100:   #fceeb5;
  --YELLOW_200:   #f9d853;
  --YELLOW_300:   #f9d853;
  --YELLOW_500:   #ddb308;
  --YELLOW_600:   #ac8b06;

  /* Gray */
  --GRAY_COLOR: #8f8f8f;
  --GRAY_50:    #f2f2f2;
  --GRAY_100:   #d9d9d9;
  --GRAY_200:   #bfbfbf;
  --GRAY_300:   #a6a6a6;
  --GRAY_400:   #858484;
  --GRAY_500:   #5d5d5d;

  /* Blue */
  --BLUE_COLOR: #3B82F6;
  --BLUE_50:    #EBF2FE;
  --BLUE_100:   #D7E6FD;
  --BLUE_200:   #B0CDFB;
  --BLUE_300:   #89B4FA;
  --BLUE_400:   #629BF8;
  --BLUE_500:   #3B82F6;
  --BLUE_600:   #0B61EE;
  --BLUE_700:   #084BB8;
  --BLUE_800:   #063583;
  --BLUE_900:   #041F4D;
}
